#root {
  text-align: center;
  background-image: url('background.png');
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

.app-menu {
  background: transparent !important;
  a {
    color: #1c0647;
  }
}

@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

figure.media {
  min-height: 400px;
}

.MuiTextField-root {
  opacity: 0.8;
  background-color: #ffce00;
  color: #1c0647;
}

.noArrows {
  & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & input[type=number] {
    -moz-appearance: textfield;
  }
}
